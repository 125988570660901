var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'ServiceInfo',"size":"xl","headerText":_vm.$t('Services.data'),"headerIcon":_vm.service.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.fullCode,"title":_vm.$t('Services.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.serviceNameAr,"title":_vm.$t('Services.nameAr'),"imgName":'services.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.serviceNameEn,"title":_vm.$t('Services.nameEn'),"imgName":'services.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.serviceNameUnd,"title":_vm.$t('Services.nameUnd'),"imgName":'services.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.isDataExist(
          _vm.service.placeInfoData ? _vm.service.placeInfoData.placeNameCurrent : ''
        ),"title":_vm.$t('Places.name'),"imgName":'services.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.isDataExist(
          _vm.service.serviceTypeData
            ? _vm.service.serviceTypeData.serviceTypeNameCurrent
            : ''
        ),"title":_vm.$t('ServiceTypes.name'),"imgName":'serviceTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.isDataExist(
          _vm.service.educationalCategoryInfoData
            ? _vm.service.educationalCategoryInfoData
                .educationalCategoryNameCurrent
            : ''
        ),"title":_vm.$t('EducationalCategories.name'),"imgName":'EducationalCategories.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.servicePrice,"title":_vm.$t('Services.servicePrice'),"imgName":'money.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.serviceMaximumDiscountPercentage,"title":_vm.$t('Services.maximumDiscountPercentage'),"imgName":'percentage.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.service.serviceNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }